// MIGRATION: New version synced with chakra
export const breakpoints = {
	base: '0em', // 0px
	xs: '20rem', // 320px
	sm: '40rem', // 640px
	md: '47.5em', // ~760px
	lg: '64rem', // 1024px
	// new xl
	nxl: '80rem', // 1280px
	// new 2xl
	n2xl: '96rem', // 1536px

	/* @deprecated */
	xl: '90em', // ~1440px (duplicate for compatibility)

	/* @deprecated */
	'2xl': '90em' // ~1440px
}
