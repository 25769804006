// MIGRATION: Synced with tailwind
export const colors = {
	black: '#000000',
	white: '#FFFFFF',
	brand: {
		50: '#FFB17A',
		100: '#FF9E59',
		200: '#FF5F00',
		300: '#FF3D00'
	},
	primary: {
		100: '#078691'
	},
	gray: {
		100: '#F4F4F4',
		200: '#B4B4B4',
		300: '#7E7E7E',
		400: '#474747',
		500: '#31393C'
	},
	yellow: {
		100: '#FFEBA8'
	},
	red: {
		100: '#FACDD4',
		200: '#D40101'
	},
	green: {
		100: '#C7E9CC'
	},
	blue: {
		100: '#D5E8FF'
	}
}
