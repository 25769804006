/* eslint-disable react-refresh/only-export-components */
import { useContext, useEffect } from 'react'
import {
	Links,
	LinksFunction,
	Meta,
	MetaFunction,
	Outlet,
	Scripts,
	ScrollRestoration
} from 'react-router'

import { withEmotionCache } from '@emotion/react'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'

import { ThemeProvider } from '@wwt/ui/theme'

import { ClientStyleContext, ServerStyleContext } from './context'
import './main.css'
import { persistOptions, queryClient } from './query'
import { TooltipProvider } from './shared/ui/Tooltip'

export const meta: MetaFunction = () => [{ title: 'WWT' }]

export const links: LinksFunction = () => [
	{ rel: 'icon', href: `/favicons-${import.meta.env.MODE}/favicon.ico` },
	{
		rel: 'icon',
		type: 'image/svg+xml',
		href: `/favicons-${import.meta.env.MODE}/favicon.svg`
	},
	// Safari
	{
		rel: 'mask-icon',
		type: 'image/svg+xml',
		href: `/favicons-${import.meta.env.MODE}/favicon.svg`,
		color: '#000000'
	},
	{
		rel: 'apple-touch-icon',
		sizes: '180x180',
		href: `/favicons-${import.meta.env.MODE}/apple-touch-icon.png`
	},
	{
		rel: 'icon',
		type: 'image/png',
		sizes: '32x32',
		href: `/favicons-${import.meta.env.MODE}/favicon-32x32.png`
	},
	{
		rel: 'icon',
		type: 'image/png',
		sizes: '16x16',
		href: `/favicons-${import.meta.env.MODE}/favicon-16x16.png`
	},
	{ rel: 'manifest', href: `/favicons-${import.meta.env.MODE}/manifest.json` }
]

interface LayoutProps {
	children: React.ReactNode
}

export const Layout = withEmotionCache(
	({ children }: LayoutProps, emotionCache) => {
		const serverStyleData = useContext(ServerStyleContext)
		const clientStyleData = useContext(ClientStyleContext)

		// Only executed on client
		useEffect(() => {
			// re-link sheet container
			emotionCache.sheet.container = document.head
			// re-inject tags
			const tags = emotionCache.sheet.tags
			emotionCache.sheet.flush()
			tags.forEach(tag => {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				;(emotionCache.sheet as any)._insertTag(tag)
			})
			// reset cache to reapply global styles
			clientStyleData?.reset()
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [])

		return (
			<html lang="en">
				<head>
					<meta charSet="UTF-8" />
					<meta
						name="viewport"
						content="width=device-width, minimum-scale=1.0, initial-scale=1.0"
					/>
					<Meta />
					<Links />

					{/* Meta Pixel Code */}
					<script
						suppressHydrationWarning
						dangerouslySetInnerHTML={{
							__html: `!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '1184546122895707');
fbq('track', 'PageView');`
						}}
					/>
					{/* End Meta Pixel Code */}
					{serverStyleData?.map(({ key, ids, css }) => (
						<style
							key={key}
							data-emotion={`${key} ${ids.join(' ')}`}
							dangerouslySetInnerHTML={{ __html: css }}
						/>
					))}
				</head>
				<body>
					{children}
					<ScrollRestoration getKey={location => location.pathname} />
					<Scripts />

					{/* Meta Pixel Code */}
					<noscript>
						{/* eslint-disable-next-line jsx-a11y/alt-text */}
						<img
							height="1"
							width="1"
							style={{ display: 'none' }}
							src="https://www.facebook.com/tr?id=1184546122895707&ev=PageView&noscript=1"
						/>
					</noscript>
					{/* End Meta Pixel Code */}
				</body>
			</html>
		)
	}
)

interface QueryProviderProps {
	children: React.ReactNode
}

const QueryProvider = ({ children }: QueryProviderProps) => {
	if (persistOptions !== undefined) {
		return (
			<PersistQueryClientProvider
				client={queryClient}
				persistOptions={persistOptions}
			>
				{children}
			</PersistQueryClientProvider>
		)
	} else {
		return (
			<QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
		)
	}
}

const Root = () => {
	return (
		<QueryProvider>
			<ThemeProvider>
				<TooltipProvider>
					<Outlet />
				</TooltipProvider>
			</ThemeProvider>
			<ReactQueryDevtools initialIsOpen={false} />
		</QueryProvider>
	)
}

export default Root
