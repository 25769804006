// MIGRATION: Synced with tailwind
export const borderRadius = {
	// MIGRATION: sm is invalid border size, add comment to figma for fix
	sm: '0.75rem', // 12px

	// MIGRATION: md in tailwind is DEFAULT
	md: '1rem', // 16px (the most popular borderRadius)

	// MIGRATION: lg is invalid border size, add comment to figma for fix
	lg: '1.25rem', // 20px

	// MIGRATION: max in tailwind is full
	max: '6249.9375rem' // 99999px for circles
}
