// MIGRATION: Font sizes synced with tailwind
export const textStyles = {
	'headline-1': {
		fontWeight: { base: 'medium', md: 'normal' },
		fontSize: { base: '2xl', md: '6xl' }
	},
	'headline-2': {
		fontWeight: { base: 'semibold', md: 'medium' },
		fontSize: { base: 'xl', md: '3xl', xl: '5xl' }
	},
	'headline-3': {
		fontWeight: { base: 'normal', md: 'medium' },
		fontSize: { base: 'xl', xl: '3xl' }
	},
	'headline-4': {
		fontWeight: { base: 'semibold', xl: 'medium' },
		fontSize: { base: 'lg', xl: 'xl' }
	},
	'body-text-1': {
		fontWeight: 'normal',
		fontSize: 'xl'
	},
	'body-text-2': {
		fontWeight: 'semibold',
		fontSize: 'lg'
	},
	'body-text-3': {
		fontWeight: 'medium',
		fontSize: 'lg'
	},
	'body-text-4': {
		fontWeight: 'normal',
		fontSize: 'lg'
	},
	'body-text-5': {
		fontWeight: 'medium',
		fontSize: 'md'
	},
	'body-text-6': {
		fontWeight: 'normal',
		fontSize: 'md'
	},
	'body-text-7': {
		fontWeight: 'normal',
		fontSize: 'sm'
	},
	'body-text-8': {
		fontWeight: 'normal',
		fontSize: 'xs'
	},
	'body-text-9': {
		fontWeight: 'normal',
		fontSize: 'xxs'
	},
	'button-extra-large': {
		fontWeight: 'semibold',
		fontSize: '4xl'
	},
	'button-large': {
		fontWeight: 'semibold',
		fontSize: 'xl'
	},
	button: {
		fontWeight: 'semibold',
		fontSize: 'md'
	},
	'hyperlink-button-1': {
		fontWeight: 'medium',
		fontSize: { base: 'md', md: 'lg' }
	},
	'hyperlink-button-2': {
		fontWeight: 'medium',
		fontSize: { base: 'sm', md: 'md' }
	},
	'hyperlink-button-3': {
		fontWeight: 'medium',
		fontSize: 'sm'
	}
}
